import React, { useEffect } from 'react';
import history from '../../history';
import { RouteComponentProps } from 'react-router';
import OfferDetail from './OfferDetail';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedJob, fetchJobById } from '../../actions/jobs';
import { RootState } from '../../store/configureStore';
import SuccessLearnMore from './learnMore/SuccessLearnMore';
import LearnMore from './learnMore/LearnMore';

interface MatchParams {
  id: string;
}

interface OfferRouteComponentProps extends RouteComponentProps<MatchParams> {
  isMobileTablet: boolean;
}

const OfferDetailedScreen = ({
  match,
  isMobileTablet,
}: OfferRouteComponentProps) => {
  const jobId = match?.params?.id;

  const dispatch = useDispatch();
  const selectedJob = useSelector(
    (state: RootState) => state.ppOffers.selectedJob
  );

  useEffect(() => {
    dispatch(fetchJobById(parseInt(jobId)));
  }, []);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignSelf: 'center',
          marginBottom: 100,
        }}
      >
        <OfferDetail
          isMobileTablet={isMobileTablet}
          applyPressed={(offerId: number) =>
            history.push('/resume', {
              isSingleOffer: true,
              offerId,
              office_name: selectedJob.office?.office_name || 'Office',
            })
          }
          backClicked={() => dispatch(clearSelectedJob())}
          selectedOffer={selectedJob}
          learnMordePressed={() =>
            history.push(`/jobs/${jobId}/interested`, { selectedJob })
          }
        />
      </div>
    </div>
  );
};

export default OfferDetailedScreen;
