import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OfferPreviewCard from './OfferPreviewCard';
import { Job } from '../../models/Job';
import { fetchPPJobsPage } from '../../actions/jobs';
import { RootState } from '../../store/configureStore';
import logoIcon from '../../images/logo_small.svg';
import LearnMoreBanner from './learnMore/LearnMoreBanner';

const OfferList = ({
  selectedOfferId,
  showDetailsOffer,
  isMobileTablet,
  clickLearnMore,
}: {
  selectedOfferId: number;
  isMobileTablet: boolean;
  showDetailsOffer: (offer: Job) => void;
  clickLearnMore: () => void;
}) => {
  const dispatch = useDispatch();
  const offers = useSelector((state: RootState) => state.ppOffers.ppOffers);

  if (offers.length === 0) {
    return (
      <div
        className="container card mt-4"
        style={{
          padding: 70,
          borderRadius: 10,
          boxShadow: '0px 3px 10px -3px #738791',
          overflowY: 'auto',
          height: 650,
        }}
      >
        <div
          className="card-body"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logoIcon} style={{ width: 50 }} alt="profile" />
          <text className="filter-blue-title mt-3">Welcome!</text>
          <text className="input-title mt-2">Check Back Soon For Offers!</text>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        paddingTop: 30,
        height: '80%',
      }}
    >
      {offers.map((offer: Job) => (
        <OfferPreviewCard
          key={offer.id}
          showDetailsOffer={showDetailsOffer}
          offer={offer}
          isSelectedOffer={offer.id === selectedOfferId}
        />
      ))}
      <LearnMoreBanner
        clickLearnMore={() => clickLearnMore()}
        isMobileTablet={isMobileTablet}
      />
      <div style={{ height: 100 }} />
    </div>
  );
};
export default OfferList;
