import React from 'react';
import { useLocation } from 'react-router-dom';
import history from '../../history';
import ResumeForm from './ResumeForm';

const Resume = ({
  showDetailsOffer,
  isMobileTablet,
}: {
  showDetailsOffer: () => void;
  isMobileTablet: boolean;
}) => {
  const location = useLocation<{ offerId: number; office_name: string }>();
  const offerInfo = location.state;
  return (
    <div
      className="container"
      style={{
        maxWidth: isMobileTablet ? '100%' : 550,
        marginTop: isMobileTablet ? 0 : 30,
        marginBottom: 50,
      }}
    >
      <ResumeForm
        officeName={offerInfo.office_name}
        offerId={offerInfo.offerId}
        isMobileTablet={isMobileTablet}
      />
    </div>
  );
};

export default Resume;
