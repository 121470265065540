import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import history from '../../../history';
import backBlueImage from '../../../images/back_blue.svg';

import iphoneIcon from '../../../images/iphoneIconV3.png';
import { RootState } from '../../../store/configureStore';

const SuccessLearnMore = ({
  backToJobsClicked,
  isMobileTablet,
}: {
  backToJobsClicked: () => void;
  isMobileTablet: boolean;
}) => {
  const isOffersAvailible = useSelector(
    (state: RootState) => state.ppOffers.ppOffers.length !== 0
  );

  return (
    <div
      className={
        isMobileTablet ? 'mobile-card' : 'container card mt-4 content-card'
      }
      style={{
        alignItems: 'center',
        maxWidth: '100%',
        width: '100%',
      }}
    >
      <text className="filter-blue-title mb-3">Thank You</text>
      <text className="common-text-bold mb-4">Your request has been sent.</text>
      <text className="filter-blue-title mb-3">What’s next?</text>

      <text className="common-text-bold mb-4 text-center">
        Our specialists will review your request and get back to you as soon as
        possible. In the meantime, you can download The GoTu app and stay
        informed on the latest jobs and status of your applications.
      </text>
      <button
        className="btn"
        style={{
          borderRadius: 25,
          marginBottom: 30,
          width: '100%',
          minHeight: 50,
          backgroundColor: '#3EB0CA',
        }}
        onClick={() => window.open('https://tempmee.com/app', '_blank')}
      >
        <text className="white-text-in-button">Download The GoTu App</text>
      </button>
      <img
        src={iphoneIcon}
        style={{
          marginBottom: 10,
          height: 188,
        }}
        alt="logo"
      />
      {isOffersAvailible ? (
        <div style={{ cursor: 'pointer' }} onClick={() => backToJobsClicked()}>
          <img
            src={backBlueImage}
            alt="back"
            style={{ width: 10, height: 15, marginRight: 10 }}
          />
          <text className="filter-blue-title"> Back to Jobs</text>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};
export default SuccessLearnMore;
