import SuccessLearnMore from './SuccessLearnMore';
import LearnMore from './LearnMore';
import { useLocation } from 'react-router-dom';
import {
  createErrorObjectSelector,
  createLoadingSelector,
} from '../../../apis/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';
import { interestedPPJob, hideError } from '../../../actions/jobs';
import { RequestInfo } from '../../../models/Resume';
import history from '../../../history';
import CustomPortal from '../../CustomPortal';
import { Job } from '../../../models/Job';

const LearnMoreScreen = ({ isMobileTablet }: { isMobileTablet: boolean }) => {
  const location = useLocation<{ selectedJob: Job }>();
  const { selectedJob } = location.state;
  const dispatch = useDispatch();
  const actions = ['LEARN_MORE_PP_JOB'];

  const errorSelector = createErrorObjectSelector(actions);
  const error = useSelector((state) => errorSelector(state));

  const showLearnMoreSuccess = useSelector(
    (state: RootState) => state.ppOffers.showLearnMoreSuccess
  );

  const submitHasBeenClicked = (requestedInfo: RequestInfo) => {
    dispatch(interestedPPJob(requestedInfo, selectedJob.id));
  };

  const renderRightCard = () => {
    if (showLearnMoreSuccess) {
      return (
        <SuccessLearnMore
          isMobileTablet={isMobileTablet}
          backToJobsClicked={() => {
            history.goBack();
          }}
        />
      );
    } else {
      return (
        <LearnMore
          isMobileTablet={isMobileTablet}
          closeLearnMore={() => history.goBack()}
          submitHasBeenClicked={(requestedInfo: RequestInfo) =>
            submitHasBeenClicked(requestedInfo)
          }
        />
      );
    }
  };

  return (
    <div
      className="container"
      style={{
        maxWidth: isMobileTablet ? '100%' : 550,
        marginTop: isMobileTablet ? 0 : 30,
        marginBottom: isMobileTablet ? 0 : 30,
      }}
    >
      {renderRightCard()}
      {error ? (
        <div className="card mt-4">
          <CustomPortal
            isMobileTablet={isMobileTablet}
            title=""
            text={error?.error?.message || 'Error'}
            submitText="OK"
            submitPressed={() => dispatch(hideError(error.action))}
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default LearnMoreScreen;
