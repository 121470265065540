import { useState } from 'react';
import backBlueImage from '../../../images/back_blue.svg';
import { RequestInfo } from '../../../models/Resume';
import CustomPortal from '../../CustomPortal';
import { Spinner } from 'react-bootstrap';
import phoneHook from '../../../hooks/phoneHook';
import { validateEmail } from '../../../hooks/emailValidation';
import { createLoadingSelector } from '../../../apis/selectors';
import { useSelector } from 'react-redux';

const LearnMore = ({
  closeLearnMore,
  submitHasBeenClicked,
  isMobileTablet,
}: {
  closeLearnMore: () => void;
  submitHasBeenClicked: (requestedInfo: RequestInfo) => void;
  isMobileTablet: boolean;
}) => {
  const actions = ['LEARN_MORE_PP_JOB'];

  const loadingSelector = createLoadingSelector(actions);
  const isLoading = useSelector((state) => loadingSelector(state));

  const savedResume = JSON.parse(sessionStorage.getItem('Resume') || '{}');
  const [firstName, setFirstName] = useState(savedResume?.first_name || '');
  const [lastName, setLastName] = useState(savedResume?.last_name || '');

  const [email, setEmail] = useState(savedResume?.email_id || '');
  const [requestedInfo, setRequestedInfo] = useState('');
  const [phone, setPhone] = phoneHook(savedResume.contact_number || '');

  const [callCheckbox, setCallCheckbox] = useState(false);
  const [textCheckbox, setTextCheckbox] = useState(false);

  const [showCheckboxAlert, setShowCheckboxAlert] = useState(false);
  const [showEmailIsNotValid, setShowEmailIsNotValid] = useState(false);

  const submitAction = () => {
    if (
      firstName === '' ||
      lastName === '' ||
      email === '' ||
      requestedInfo === '' ||
      phone === ''
    ) {
      setShowCheckboxAlert(true);
    } else if (!validateEmail(email)) {
      setShowEmailIsNotValid(true);
    } else {
      submitHasBeenClicked({
        first_name: firstName,
        last_name: lastName,
        email_id: email,
        contact_number: phone,
        call: callCheckbox,
        text: textCheckbox,
        requestedInfo,
      });
    }
  };
  return (
    <div
      className={
        isMobileTablet ? 'mobile-card' : 'container card mt-4 content-card'
      }
      style={{
        maxWidth: '100%',
      }}
    >
      <div
        style={{ width: '100%', cursor: 'pointer' }}
        onClick={() => closeLearnMore()}
      >
        <img src={backBlueImage} alt="back" className="back-blue-icon" />
        <text className="filter-blue-title"> Learn More</text>
      </div>
      <text className="common-large-text-bold mt-4 mb-4">
        Fill this out so we can learn more about you and your needs.
      </text>
      <div style={{ width: '100%' }}>
        <text className="common-text-bold"> First Name</text>
        <input
          className="input-blue-border form-control mb-2"
          value={firstName}
          onChange={(value) => setFirstName(value.currentTarget.value)}
        />
      </div>
      <div style={{ width: '100%' }}>
        <text className="common-text-bold"> Last Name</text>
        <input
          className="input-blue-border form-control mb-2"
          value={lastName}
          onChange={(value) => setLastName(value.currentTarget.value)}
        />
      </div>
      <div style={{ width: '100%' }}>
        <text className="common-text-bold">Email </text>
        <input
          type="email"
          className="input-blue-border form-control  mb-2"
          value={email}
          onChange={(value) => setEmail(value.currentTarget.value)}
        />
      </div>
      <div style={{ width: '100%' }}>
        <text className="common-text-bold">Mobile Number </text>
        <input
          className="input-blue-border form-control  mb-2"
          value={phone}
          onChange={(value) => setPhone(value.currentTarget.value)}
        />
      </div>
      <div style={{ width: '100%' }}>
        <text className="common-text-bold"> Communication Preference </text>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 5,
            marginBottom: 10,
          }}
        >
          <div className="form-check mr-5">
            <input
              className="form-check-input"
              type="checkbox"
              checked={callCheckbox}
              style={{ backgroundColor: callCheckbox ? '#3eb0ca' : undefined }}
              id="callCheck"
              onChange={(value) => setCallCheckbox(!callCheckbox)}
            />
            <label
              className="form-check-label common-text-regular"
              htmlFor="callCheck"
            >
              Call
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={textCheckbox}
              style={{ backgroundColor: textCheckbox ? '#3eb0ca' : undefined }}
              id="textCheck"
              onChange={(value) => setTextCheckbox(!textCheckbox)}
            />

            <label
              className="form-check-label common-text-regular"
              htmlFor="textCheck"
            >
              Text
            </label>
          </div>
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <text className="common-text-bold">How can we help?</text>
        <textarea
          value={requestedInfo}
          onChange={(value) => setRequestedInfo(value.currentTarget.value)}
          className="input-blue-border form-control  mb-2"
          style={{ height: 130 }}
        />
      </div>

      {isLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Spinner
            animation="border"
            role="status"
            variant="info"
            style={{
              height: 30,
              width: 30,
              marginTop: 45,
            }}
          />
        </div>
      ) : (
        <div
          className="main-blue-button btn"
          style={{
            width: '100%',
            marginBottom: 30,
            marginTop: 20,
            height: '50px',
          }}
          onClick={() => submitAction()}
        >
          <text className="white-text-in-button">Submit</text>
        </div>
      )}

      {showCheckboxAlert ? (
        <div className="card mt-4">
          <CustomPortal
            isMobileTablet={isMobileTablet}
            title=""
            text="Please enter all needed information."
            submitText="OK"
            submitPressed={() => setShowCheckboxAlert(false)}
          />
        </div>
      ) : (
        <div />
      )}

      {showEmailIsNotValid ? (
        <div className="card mt-4">
          <CustomPortal
            isMobileTablet={isMobileTablet}
            title=""
            text="Please enter a valid email"
            submitText="OK"
            submitPressed={() => setShowEmailIsNotValid(false)}
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};
export default LearnMore;
