import { combineReducers } from 'redux';
import jobsReducer from './jobsReducer';
import loadingReducer from './loadingReducer';
import errorReducer from './errorReducer';
import userReducer from './userReducer';
import alertReducer from './alertReducer';

export default combineReducers({
  ppOffers: jobsReducer,
  loading: loadingReducer,
  error: errorReducer,
  user: userReducer,
  alert: alertReducer,
});
