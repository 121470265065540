import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

export const LocationSearchInput = ({
  onSelect,
  propAddress,
}: {
  onSelect: (address: {
    address: google.maps.GeocoderResult;
    coords: { lat: number; lng: number };
  }) => void;
  propAddress?: { address: { formatted_address: string } };
}) => {
  const [address, setAddress] = useState(
    propAddress ? propAddress?.address?.formatted_address : ''
  );

  const handleChange = (newAddress: string) => {
    setAddress(newAddress);
  };

  const handleSelect = async (newAddress: string) => {
    try {
      const results = await geocodeByAddress(newAddress);
      const coords = await getLatLng(results[0]);
      if (onSelect) {
        onSelect({
          address: results[0],
          coords,
        });
      }
      setAddress(results[0].formatted_address);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      debounce={1000}
      searchOptions={{
        componentRestrictions: { country: ['us'] },
        // types: ['postal_code'],
        types: ['address'],
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div
          style={{
            width: '100%',
          }}
        >
          <input
            {...getInputProps({
              type: 'search',
              placeholder: 'Search Places ...',
              className: 'location-search-input input-blue-border form-control',
            })}
          />

          <div
            className="autocomplete-dropdown-container"
            style={{
              position: 'absolute',
              backgroundColor: 'white',
              width: '100%',
              zIndex: 10,
            }}
          >
            {loading && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Spinner
                  animation="border"
                  role="status"
                  variant="info"
                  style={{
                    height: 30,
                    width: 30,
                    marginLeft: 20,
                    marginTop: 10,
                  }}
                />
              </div>
            )}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? {
                    backgroundColor: '#fafafa',
                    cursor: 'pointer',
                    margin: 5,
                    width: '90%',
                    padding: 3,
                  }
                : {
                    backgroundColor: '#ffffff',
                    cursor: 'pointer',
                    margin: 5,
                    width: '90%',
                    padding: 3,
                  };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};
