const LearnMoreBunner = ({
  clickLearnMore,
  isMobileTablet,
}: {
  clickLearnMore: () => void;
  isMobileTablet: boolean;
}) => {
  return (
    <div className="bunner-card card border-0 mb-3">
      <text className="bunner-blue-title mb-2">Full-Time or Part-Time</text>
      <text className="bunner-gray-text">
        If you are interested in a Registered Dental Hygienist position in
        (City)!
      </text>
      <div
        className="main-blue-button btn"
        style={{
          width: '80%',
          marginTop: 20,
          height: 50,
          borderRadius: 25,
          alignSelf: 'center',
        }}
        onClick={() => clickLearnMore()}
      >
        <text className="white-text-in-button">Click Here to Learn More</text>
      </div>
    </div>
  );
};
export default LearnMoreBunner;
