import React, { useState } from 'react';
import newxtImage from '../../images/right_white_arrow_icon.svg';
import backBlueImage from '../../images/back_blue.svg';
import { validateEmail } from '../../hooks/emailValidation';

import { useHistory } from 'react-router-dom';
import CustomPortal from '../CustomPortal';
import phoneHook from '../../hooks/phoneHook';
import { states } from './state/StateList';

const ResumeForm = ({
  officeName,
  offerId,
  isMobileTablet,
}: {
  officeName: string;
  offerId: number;
  isMobileTablet: boolean;
}) => {
  let history = useHistory();
  const savedResume = JSON.parse(sessionStorage.getItem('Resume') || '{}');
  const [firstName, setFirstName] = useState(savedResume.first_name || '');
  const [lastName, setLastName] = useState(savedResume.last_name || '');

  const [contactNumber, setContactNumber] = phoneHook(
    savedResume.contact_number || ''
  );
  const [emailId, setEmail] = useState(savedResume.email_id || '');

  const [licId, setLicId] = useState(savedResume.licId || '');
  const [issuedYear, setIssuedYear] = useState(savedResume.issuedYear || '');
  const [schoolName, setSchoolName] = useState(savedResume.schoolName || '');
  const [state, setLicState] = useState(savedResume.state || 'AL');

  const [yearsOfExperience, setYearsOfExperience] = useState(
    savedResume.yearsOfExperience || ''
  );
  const [available, setAvailable] = useState(
    savedResume.scheduleType || 'Full Time'
  );
  const [officesWorkedIn, setOfficesWorkedIn] = useState(
    savedResume.officesWorkedIn || ''
  );
  const [howFindOutAboutJob, setHowFindOutAboutJob] = useState(
    savedResume.officesWorkedIn || ''
  );

  const [interviewAvailability, setInterviewAvailability] = useState(
    savedResume.interviewAvailability || ''
  );
  const [about, setAbout] = useState(savedResume.about || '');

  const [showCheckboxAlert, setShowCheckboxAlert] = useState(false);
  const [showEmailIsNotValid, setShowEmailIsNotValid] = useState(false);

  const MAX_LENHT_ABOUT: number = 300;
  const charactersCount: number =
    MAX_LENHT_ABOUT - about.length >= 0 ? MAX_LENHT_ABOUT - about.length : 0;

  const isInfoMissed =
    firstName === '' ||
    lastName === '' ||
    contactNumber === '' ||
    emailId === '' ||
    schoolName === '' ||
    state === '' ||
    issuedYear === '' ||
    about === '' ||
    yearsOfExperience === '' ||
    officesWorkedIn === '' ||
    howFindOutAboutJob === '' ||
    interviewAvailability === '';

  const renderLine = (
    title: string,
    value: string,
    onChange: (value: React.ChangeEvent<HTMLInputElement>) => void,
    inputType: string,
    placeholder?: string
  ) => {
    return (
      <div>
        <text className="row common-text-bold mb-2">{title}</text>
        <input
          type={inputType}
          value={value}
          className="row input-blue-border form-control"
          onChange={(value) => onChange(value)}
          placeholder={placeholder}
        />
      </div>
    );
  };

  const submitResume = () => {
    if (isInfoMissed) {
      setShowCheckboxAlert(true);
    } else if (!validateEmail(emailId)) {
      setShowEmailIsNotValid(true);
    } else {
      const resumeData = {
        first_name: firstName,
        last_name: lastName,
        contact_number: contactNumber,
        email_id: emailId,
        schoolName,
        issuedYear,
        about,
        licId,
        state,
        yearsOfExperience,
        scheduleType: available,
        officesWorkedIn,
        howFindOutAboutJob,
        interviewAvailability,
      };
      sessionStorage.setItem('Resume', JSON.stringify(resumeData));
      history.push('/confirm', {
        ...resumeData,
        offerId,
      });
    }
  };

  return (
    <div
      className="container"
      style={{
        padding: isMobileTablet ? 30 : '30px 70px 70px 70px',
        marginTop: isMobileTablet ? 0 : 30,
        marginBottom: 70,
        height: '95%',
        backgroundColor: 'white',
        width: '100%',
        borderRadius: isMobileTablet ? 0 : 10,
        boxShadow: isMobileTablet ? undefined : '0px 3px 10px -3px #738791',
      }}
    >
      <div
        style={{ marginBottom: 25, cursor: 'pointer' }}
        onClick={() => history.goBack()}
      >
        <img src={backBlueImage} className="back-blue-icon" alt="back" />
        <text className="filter-blue-title" style={{ fontSize: 18 }}>
          Apply to {officeName}
        </text>
      </div>
      {renderLine(
        'First Name',
        firstName,
        (value: React.ChangeEvent<HTMLInputElement>) =>
          setFirstName(value.currentTarget.value),
        'text'
      )}
      {renderLine(
        'Last Name',
        lastName,
        (value) => setLastName(value.currentTarget.value),
        'text'
      )}
      {renderLine(
        'Contact Number',
        contactNumber,
        (value) => setContactNumber(value.currentTarget.value),
        'text'
      )}
      {renderLine(
        'Email',
        emailId,
        (value) => setEmail(value.currentTarget.value),
        'email'
      )}

      {renderLine(
        'Hygienist License Number',
        licId,
        (value) => setLicId(value.currentTarget.value),
        'text'
      )}
      <div>
        <text className="row common-text-bold mb-2">State</text>
        <div
          className="row input-blue-border form-control"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            paddingLeft: 5,
          }}
        >
          <select
            className="border-0"
            value={state}
            style={{ paddingLeft: 0 }}
            onChange={(value) => setLicState(value.currentTarget.value)}
          >
            {states.map((state) => (
              <option value={state.abbreviation}> {state.abbreviation}</option>
            ))}
          </select>
        </div>
      </div>
      {renderLine(
        'Year Issued',
        issuedYear,
        (value) => setIssuedYear(value.currentTarget.value),
        'number'
      )}
      {renderLine(
        'School Attended',
        schoolName,
        (value) => setSchoolName(value.currentTarget.value),
        'text'
      )}

      <div>
        <text className="row common-text-bold">
          Are you available part time or full time?
        </text>
        <div
          className="row input-blue-border form-control"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            paddingLeft: 5,
          }}
        >
          <select
            className="border-0"
            value={available}
            style={{ paddingLeft: 0 }}
            onChange={(value) => setAvailable(value.currentTarget.value)}
          >
            <option value="Full Time"> Full Time</option>
            <option value="Part Time">Part Time </option>
            <option value="Any (Part or Full Time)">
              Any (Part or Full Time)
            </option>
          </select>
        </div>
      </div>

      {renderLine(
        'How many years of dental industry experience do you have?',
        yearsOfExperience,
        (value) => setYearsOfExperience(value.currentTarget.value),
        'text',
        ''
      )}
      {renderLine(
        'List your last 3 employers',
        officesWorkedIn,
        (value) => setOfficesWorkedIn(value.currentTarget.value),
        'text',
        ''
      )}

      {renderLine(
        'How did you find out about this job?',
        howFindOutAboutJob,
        (value) => setHowFindOutAboutJob(value.currentTarget.value),
        'text',
        ''
      )}
      <div>
        <text className="row common-text-bold mb-2">
          When are you generally available for an interview?
        </text>
        <textarea
          className="row input-blue-border form-control"
          style={{ height: 70 }}
          value={interviewAvailability}
          placeholder="Ex. Mon - Fri between 12pm-1pm"
          onChange={(value) =>
            setInterviewAvailability(value.currentTarget.value)
          }
        />
      </div>
      <div>
        <text className="row common-text-bold mb-2">
          Tell us about yourself. ({`${charactersCount}`} characters or less)
        </text>
        <textarea
          className="row input-blue-border form-control "
          style={{ height: 150 }}
          value={about}
          placeholder="Ex. I was born and raised in Florida, and
          have a passion for the dental industry. I
          have also been a member of the ADHA
          community since 2016."
          onChange={(value) => setAbout(value.currentTarget.value)}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <div
          className="secondary-blue-button btn"
          style={{ width: '100%', marginTop: 10 }}
          onClick={() => submitResume()}
        >
          <text className="white-text-in-button">Review Resume</text>
          <img className="white-arrow-icon" src={newxtImage} alt="next" />
        </div>
      </div>
      {showCheckboxAlert ? (
        <div className="card mt-4">
          <CustomPortal
            isMobileTablet={isMobileTablet}
            title=""
            text="Please enter all needed information."
            submitText="OK"
            submitPressed={() => setShowCheckboxAlert(false)}
          />
        </div>
      ) : (
        <div />
      )}
      {showEmailIsNotValid ? (
        <div className="card mt-4">
          <CustomPortal
            isMobileTablet={isMobileTablet}
            title=""
            text="Please enter a valid email"
            submitText="OK"
            submitPressed={() => setShowEmailIsNotValid(false)}
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};
export default ResumeForm;
