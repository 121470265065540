import { actions } from './types';
import tempMee from '../apis/tempmee';
import { SignIn } from '../models/Resume';
import { AppDispatch, RootState } from '../store/configureStore';
import { logCustomEvent } from '../analytics/analytics';
import axios from 'axios';
import { fetchPPJobsPage } from './jobs';

const signUpCompleteRegistration =
  (access_token: string, contact_number: string) =>
  async (dispatch: AppDispatch | any, getState: () => RootState) => {
    try {
      dispatch({
        type: actions.COMPLETE_SING_UP_REQUEST,
      });
      const headers = {
        Authorization: access_token,
      };

      const { userInfo, placeId } = getState().user;
      const { first_name, last_name } = userInfo;

      const reqPlaceId =
        JSON.parse(sessionStorage.getItem('UserPlaceId') || '{}') || placeId;

      const resUser = await Promise.all([
        tempMee.put(
          `users/phone`,
          { phone: contact_number },
          {
            headers,
          }
        ),
        tempMee.post(
          `user/address`,
          { place_id: reqPlaceId, distance: 25 },
          {
            headers,
          }
        ),
        tempMee.patch(
          `user`,
          { first_name, last_name, contact_number, user_type: 'HYG' },
          {
            headers,
          }
        ),
      ]);

      const { schoolName, licId, issuedYear, state } = userInfo;
      if (licId) {
        const data = {
          license: licId,
          state,
          firstIssued: issuedYear,
          expirationDate: new Date(),
          school: schoolName,
          graduationDate: new Date(),
          profession: 'RDH',
        };
        await tempMee.post(`licenses`, data, {
          headers,
        });
      }
      await logCustomEvent('add_user_info', {
        ...userInfo,
        contact_number,
        send_page_view: false,
      });
      dispatch({
        type: actions.COMPLETE_SING_UP_SUCCESS,
        payload: resUser[resUser.length - 1].data,
      });
    } catch (err) {
      dispatch({ type: actions.COMPLETE_SING_UP_FAILURE, payload: err });
    }
  };

export const authSignUp =
  (data: SignIn) =>
  async (dispatch: AppDispatch | any, getState: () => RootState) => {
    try {
      dispatch({ type: actions.SING_UP_REQUEST });

      const { email_id, password, contact_number } = data;

      const response = await tempMee.post(`auth/signup`, {
        email: email_id,
        password,
        original: 'job-board',
      });
      const { access_token } = response.data;
      dispatch(signUpCompleteRegistration(access_token, contact_number));
      await logCustomEvent('sign_up', {
        email_id,
        contact_number,
        send_page_view: false,
      });

      dispatch({
        type: actions.SING_UP_SUCCESS,
        payload: access_token,
      });
    } catch (err) {
      dispatch({ type: actions.SING_UP_FAILURE, payload: err });
    }
  };

export const verifyPhone =
  (code: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const { access_token } = getState().user;
    const headers = {
      Authorization: access_token,
    };

    try {
      dispatch({ type: actions.VERIFY_PHONE_REQUEST });
      await tempMee.post(
        `users/phone/confirm`,
        { code },
        {
          headers,
        }
      );

      const resUser = await tempMee.patch(
        `user`,
        { is_profile_complete: true },
        {
          headers,
        }
      );
      await tempMee.post(
        `send_app_link`,
        { phoneNumber: resUser?.data?.contact_number },
        {
          headers,
        }
      );

      dispatch({ type: actions.VERIFY_PHONE_SUCCESS, payload: resUser.data });
    } catch (err) {
      dispatch({ type: actions.VERIFY_PHONE_FAILURE, payload: err });
    }
  };

export const getLocationByZipCode =
  ({ zipCode, radius }: { zipCode: string; radius: string }) =>
  async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch({
        type: actions.FETCH_DATA_FROM_URL_REQUEST,
        payload: { zipCode, radius },
      });

      const { placeId } = getState().user;
      if (placeId === '') {
        const response = await axios.get(
          'https://maps.googleapis.com/maps/api/geocode/json',
          {
            params: {
              address: zipCode,
              key: process.env.REACT_APP_KEY_GOOGLE_PLACE_API,
            },
          }
        );
        if (response.data?.results && response.data?.results.length !== 0) {
          const resAddress = response.data?.results[0];
          const coords = {
            lat: resAddress.geometry.location.lat,
            lng: resAddress.geometry.location.lng,
          };
          const placeId = resAddress.place_id;
          const address = resAddress.formatted_address;

          dispatch({
            type: actions.FETCH_DATA_FROM_URL_SUCCESS,
            payload: { placeId, coords, address },
          });
          dispatch(fetchPPJobsPage());
        } else {
          dispatch({
            type: actions.SHOW_MESSAGE,
            payload: {
              message:
                'Enter your primary addreas to search Local Opportunities',
              title: 'Almost Done!',
              route: '/',
            },
          });
        }
      } else {
        dispatch({
          type: actions.FETCH_DATA_FROM_URL_SUCCESS,
          payload: {},
        });
      }
    } catch (err) {
      dispatch({ type: actions.FETCH_DATA_FROM_URL_FAILURE, payload: err });
    }
  };
export const hideVerificationPopup = () => ({
  type: actions.HIDE_VER_POPUP,
});

export const hideMessage = () => ({
  type: actions.HIDE_MESSAGE,
});
