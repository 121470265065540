import { actions } from '../actions/types';

/* eslint-disable no-case-declarations */

const initialState = {
  ppOffers: [],
  ppTotal: 0,
  activeRequest: false,
  isFinalPage: false,
  showLearnMoreSuccess: false,
  ppSearchParams: {
    page: 0,
    limit: 0,
    lat: 0.0,
    lng: 0.0,
  },
  selectedJob: null,
};

const jobsReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case actions.FETCH_PP_JOBS_REQUEST:
      return {
        ...state,
        ppSearchParams: action.payload,
        activeRequest: true,
      };
    case actions.FETCH_JOB_BY_ID_SUCCESS:
      return {
        ...state,
        selectedJob: action.payload,
        showLearnMoreSuccess: false,
      };
    case actions.CLEAR_SELECTED_JOB_SUCCESS:
      return {
        ...state,
        selectedJob: null,
        showLearnMoreSuccess: false,
      };
    case actions.SHOW_LEARN_MORE_SUCCESS:
      return {
        ...state,
        showLearnMoreSuccess: true,
      };

    case actions.FETCH_PP_JOBS_FAILURE:
      return {
        ...state,
        activeRequest: false,
      };
    case actions.FETCH_PP_JOBS_SUCCESS:
      const mergedOffers = [...state.ppOffers, ...action.payload.offers];

      return {
        ...state,
        ppOffers:
          action.payload.page === 1 ? action.payload.offers : mergedOffers,
        ppTotal: action.payload.total,
        activeRequest: false,
        isFinalPage:
          action.payload.offers.length === 0 ||
          action.payload.offers.length < state.ppSearchParams.limit,
      };

    case actions.DELETE_USER_ADDRESS:
      sessionStorage.removeItem('UserCoords');
      sessionStorage.removeItem('UserAddress');
      sessionStorage.removeItem('UserPlaceId');

      return initialState;
    default:
      return state;
  }
};

export default jobsReducer;
