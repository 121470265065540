import { useState } from 'react';
import { LocationSearchInput } from './LocationSearchInput';
import { saveUserAddress } from '../../actions/jobs';
import { useDispatch } from 'react-redux';
import newxtImage from '../../images/right_white_arrow_icon.svg';

const ShiftsInArea = ({ isMobileTablet }: { isMobileTablet: boolean }) => {
  const [coords, setCoords] = useState({ lat: 0.0, lng: 0.0 });
  const [address, setAddress] = useState('');
  const [placeId, setPlaceId] = useState('');

  const dispatch = useDispatch();

  return (
    <div style={{ marginTop: 30 }}>
      <div className="container">
        <p
          className="text-center"
          style={{
            color: '#3EB0CA',
            fontFamily: 'Nunito',
            fontSize: 36,
            fontWeight: 'bold',
          }}
        >
          Search Local Opportunities
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: isMobileTablet ? 'column' : 'row',
          }}
        >
          <div className="col  d-flex justify-content-center flex-column">
            <p className="input-title">Primary Address</p>
            <LocationSearchInput
              onSelect={(address: {
                address: google.maps.GeocoderResult;
                coords: { lat: number; lng: number };
              }) => {
                setPlaceId(address.address.place_id);
                setCoords(address.coords);
                setAddress(address.address.formatted_address);
              }}
            />
          </div>
          <div
            className="col d-flex justify-content-center flex-column"
            style={{ marginTop: isMobileTablet ? 20 : 0 }}
          >
            <p className="input-title">Position</p>
            <select
              className="input-blue-border form-select-info"
              style={{ padding: 5 }}
            >
              <option className="options" value="RDH" key="RDH">
                Registered Dental Hygienist
              </option>
            </select>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{ width: isMobileTablet ? '80%' : '' }}
            className="secondary-blue-button btn"
            onClick={() =>
              dispatch(saveUserAddress(coords, address, placeId, '25'))
            }
          >
            <text className="white-text-in-button">Search Near Me </text>
            <img src={newxtImage} alt="next" className="white-arrow-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftsInArea;
