import { actions } from './types';
import tempMee from '../apis/tempmee';
import { ResumeInfoInterface, RequestInfo } from '../models/Resume';
import { AppDispatch, RootState } from '../store/configureStore';
import history from '../history';
import { logCustomEvent } from '../analytics/analytics';

const PP_OFFERS_LIMIT = 25;

export const fetchPPJobsPage =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { activeRequest, isFinalPage } = getState().ppOffers;

      const { userCoords } = getState().user;

      const isCoordsEnabled = userCoords.lat !== 0 && userCoords.lng !== 0;
      const coords = isCoordsEnabled
        ? userCoords
        : JSON.parse(sessionStorage.getItem('UserCoords') || '{}');

      const { ppSearchParams } = getState().ppOffers;

      if (
        activeRequest ||
        isFinalPage ||
        (coords.lat === 0 && coords.lng === 0)
      )
        return;

      let { search } = window.location
      let splitR = search?.split('radius=');
      let radius = splitR?.[1]?.split('?')?.[0];

      const page = ppSearchParams.page || 0;
      const newSearchParams = {
        ...coords,
        page: page + 1,
        limit: PP_OFFERS_LIMIT,
        radius: radius || '25'
      };

      dispatch({
        type: actions.FETCH_PP_JOBS_REQUEST,
        payload: newSearchParams,
      });
      const response = await tempMee.get('pp/job_board', {
        params: newSearchParams,
      });
      dispatch({ type: actions.FETCH_PP_JOBS_SUCCESS, payload: response.data });
    } catch (err) {
      dispatch({ type: actions.FETCH_PP_JOBS_FAILURE, payload: err });
    }
  };

export const fetchJobById =
  (jobId: number) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { selectedJob } = getState().ppOffers;
      if (selectedJob?.id === jobId) {
        return;
      }
      dispatch({ type: actions.FETCH_JOB_BY_ID_REQUEST });
      const response = await tempMee.get(`pp/job_board/${jobId}`);

      await logCustomEvent('job_details', {
        ...selectedJob,
        send_page_view: false,
      });
      dispatch({
        type: actions.FETCH_JOB_BY_ID_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      dispatch({ type: actions.FETCH_JOB_BY_ID_FAILURE, payload: err });
    }
  };

export const clearSelectedJob =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch({ type: actions.CLEAR_SELECTED_JOB_REQUEST });
    try {
      dispatch({
        type: actions.CLEAR_SELECTED_JOB_SUCCESS,
      });
    } catch (err) {
      dispatch({ type: actions.CLEAR_SELECTED_JOB_FAILURE, payload: err });
    }
  };

export const applyPPJob =
  (state: ResumeInfoInterface) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch({ type: actions.APPLY_PP_JOB_REQUEST });
    try {
      const response = await tempMee.post(
        `pp/job_board/${state.offerId}/apply`,
        state
      );

      if (response.data.userExist) {
        history.push('/success-resume');
      } else {
        dispatch({
          type: actions.SAVE_USER_INFO_SUCCESS,
          payload: state,
        });
        history.push('/signup', { ...state });
      }
      await logCustomEvent('apply_job', {
        ...state,
        send_page_view: false,
      });
      dispatch({ type: actions.APPLY_PP_JOB_SUCCESS });
    } catch (err) {
      dispatch({ type: actions.APPLY_PP_JOB_FAILURE, payload: err });
    }
  };

export const interestedPPJob =
  (requestedInfo: RequestInfo, offerId: number) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch({ type: actions.LEARN_MORE_PP_JOB_REQUEST });
      const res = await tempMee.post(
        `pp/job_board/${offerId}/interested`,
        requestedInfo
      );
      if (res.data.userExist) {
        dispatch({ type: actions.SHOW_LEARN_MORE_SUCCESS });
      } else {
        dispatch({
          type: actions.SAVE_USER_INFO_SUCCESS,
          payload: requestedInfo,
        });
        history.push('/signup', { ...requestedInfo });
      }

      await logCustomEvent('interested_job', {
        ...requestedInfo,
        offerId,
        send_page_view: false,
      });
      dispatch({ type: actions.LEARN_MORE_PP_JOB_SUCCESS });
    } catch (err) {
      dispatch({ type: actions.LEARN_MORE_PP_JOB_FAILURE, payload: err });
    }
  };

export const saveUserAddress =
  (coords: { lat: number; lng: number }, address: string, placeId: string, radius: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch({ type: actions.SAVE_USER_ADDRESS_REQUEST });
    try {
    
      history.push({
        pathname: '/jobs',
        search: `?radius=${radius}`
      })
      const data = { coords, address, placeId, radius };
      await logCustomEvent('add_address', {
        ...data,
        send_page_view: false,
      });
      dispatch({
        type: actions.SAVE_USER_ADDRESS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({ type: actions.SAVE_USER_ADDRESS_FAILURE, payload: err });
    }
  };

export const hideError = (type: string) => ({
  type: actions.HIDE_ERROR,
  payload: type,
});

export const deleteUserAddress =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch({ type: actions.DELETE_USER_ADDRESS });
    history.push('/');
  };
