const About = () => {
  return (
    <div className="container mt-5">
      <text className="filter-blue-title" style={{ fontSize: 36 }}>
        About GoTu
      </text>
      <br /> <br />
      <text className="common-text-regular">
        GoTu is the #1 app for quickly filling short-term Dental Professionals
        Positions. GoTu is designed to fill open, temporary positions with
        local, qualified talent by replacing the traditional “sub-list” dynamic.
        <br /> <br />
        By becoming a GoTu Dental Office, you’ll have the ability to quickly and
        effectively connect with available Dental Professional, ensuring that
        your business runs optimally. This will significantly reduce the time
        and energy you spend searching for temporary help, allowing your office
        to devote attention to what is most important; YOUR PATIENTS.
        <br /> <br />
        Our GoTu are notified of available positions in their chosen area(s),
        inviting them to fill available shifts and put their hard-earned and
        valuable skills to work. Payment transactions, pending jobs, open jobs,
        and work history are all facilitated and coordinated via the GoTu app.
      </text>
    </div>
  );
};
export default About;
