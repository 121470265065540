import React, { useState } from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

import CustomPortal from '../CustomPortal';
import { authSignUp } from '../../actions/user';
import { RequestInfo, UserModel } from '../../models/Resume';
import { useDispatch } from 'react-redux';
import { validateEmail } from '../../hooks/emailValidation';

const SignUpForm = ({
  isMobileTablet,
  isLoading,
}: {
  isMobileTablet: boolean;
  isLoading: boolean;
}) => {
  let history = useHistory();

  const location = useLocation<RequestInfo>();
  const userInfo = location.state;
  const [contactNumber, setContactNumber] = useState(
    userInfo?.contact_number || ''
  );
  const [emailId, setEmail] = useState(userInfo?.email_id || '');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [showCheckboxAlert, setShowCheckboxAlert] = useState(false);
  const [passwordNotMatched, setPasswordNotMatched] = useState(false);
  const [showEmailIsNotValid, setShowEmailIsNotValid] = useState(false);

  const dispatch = useDispatch();
  const isInfoMissed =
    password === '' ||
    confirmPassword === '' ||
    contactNumber === '' ||
    emailId === '';

  const renderLine = (
    title: string,
    value: string,
    onChange: (value: React.ChangeEvent<HTMLInputElement>) => void,
    inputType: string,
    placeholder?: string
  ) => {
    return (
      <div className="mt-3 " style={{ width: '100%' }}>
        <text className="common-text-bold mb-2 ">{title}</text>
        <input
          type={inputType}
          value={value}
          className="input-blue-border form-control"
          onChange={(value) => onChange(value)}
          style={{ width: '100%' }}
          placeholder={placeholder}
        />
      </div>
    );
  };
  const submitResume = async () => {
    if (isInfoMissed) {
      setShowCheckboxAlert(true);
    }
    if (password !== confirmPassword) {
      setPasswordNotMatched(true);
    } else if (!validateEmail(emailId)) {
      setShowEmailIsNotValid(true);
    } else {
      const data: UserModel = {
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        email_id: emailId,
        contact_number: contactNumber,
        password,
      };
      await dispatch(authSignUp(data));
    }
  };

  return (
    <div
      className="container"
      style={{
        padding: isMobileTablet ? 30 : '30px 70px 70px 70px',
        marginTop: isMobileTablet ? 0 : 30,
        marginBottom: 70,
        height: '95%',
        backgroundColor: 'white',
        width: '100%',
        borderRadius: isMobileTablet ? 0 : 10,
        boxShadow: isMobileTablet ? undefined : '0px 3px 10px -3px #738791',
      }}
    >
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <text
            className="filter-blue-title mt-2"
            style={{
              fontSize: 20,
              textAlign: 'center',
              width: '100%',
            }}
          >
            Start Creating Your Account to View Local Temp & Permanent
            Opportunities
          </text>
        </div>

        {renderLine(
          'Email',
          emailId,
          (value: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(value.currentTarget.value),
          'email'
        )}

        {renderLine(
          'Contact Number',
          contactNumber,
          (value) => setContactNumber(value.currentTarget.value),
          'text'
        )}
        {renderLine(
          'Password',
          password,
          (value) => setPassword(value.currentTarget.value),
          'password'
        )}
        {renderLine(
          'Confirm Password',
          confirmPassword,
          (value) => setConfirmPassword(value.currentTarget.value),
          'password'
        )}
        <div>
          <p
            className="common-text-regular mt-3"
            style={{
              textAlign: 'center',
              fontSize: 14,
            }}
          >
            Have a question?
            <br /> Call/Text 786-460-2170 for 1-on-1 help from a real person!
          </p>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                width: isMobileTablet ? '80%' : '',
                marginTop: 10,
                backgroundColor: isInfoMissed ? '#97A5AC' : '#f4511e',
              }}
              className="secondary-blue-button btn"
              onClick={() => submitResume()}
            >
              {isLoading ? (
                <Spinner
                  animation="border"
                  role="status"
                  variant="light"
                  style={{
                    height: 30,
                    width: 30,
                  }}
                />
              ) : (
                <text className="white-text-in-button">Submit</text>
              )}
            </div>
          </div>
        </div>
      </div>
      {showCheckboxAlert ? (
        <div className="card mt-4">
          <CustomPortal
            isMobileTablet={isMobileTablet}
            title=""
            text="Password and confirm password not match."
            submitText="OK"
            submitPressed={() => setShowCheckboxAlert(false)}
          />
        </div>
      ) : (
        <div />
      )}
      {passwordNotMatched ? (
        <div className="card mt-4">
          <CustomPortal
            isMobileTablet={isMobileTablet}
            title=""
            text="Please enter all needed information."
            submitText="OK"
            submitPressed={() => setPasswordNotMatched(false)}
          />
        </div>
      ) : (
        <div />
      )}
      {showEmailIsNotValid ? (
        <div className="card mt-4">
          <CustomPortal
            isMobileTablet={isMobileTablet}
            title=""
            text="Please enter a valid email"
            submitText="OK"
            submitPressed={() => setShowEmailIsNotValid(false)}
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};
export default SignUpForm;
