import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import backBlueImage from '../../images/back_blue.svg';
import Portal from '../Portal';

import iphoneIcon from '../../images/iphoneIconV3.png';
import { RootState } from '../../store/configureStore';

const SignUpErrorPopup = ({
  backToJobsClicked,
  isMobileTablet,
}: {
  backToJobsClicked: () => void;
  isMobileTablet: boolean;
}) => {
  const { email_id } = useSelector((state: RootState) => state.user.userInfo);
  return (
    <Portal>
      <div
        className="modal"
        style={{ minWidth: isMobileTablet ? '100%' : 1140 }}
      >
        <div
          className="modal_content"
          style={{ width: isMobileTablet ? '90%' : '50%', maxWidth: 650 }}
        >
          <text className="red-title mb-3 mt-3" style={{ fontSize: 20 }}>
            Almost Done!
          </text>
          <text
            className="common-text-regular mb-4"
            style={{ textAlign: 'center' }}
          >
            Download the app and login with your email:{' '}
            <strong> {email_id}</strong> and Password to complete your account
            and start taking control of your work/life balance with GoTu!
          </text>

          <button
            className="btn blue-button"
            style={{ backgroundColor: '#f4511e' }}
            onClick={() => window.open('https://tempmee.com/app', '_blank')}
          >
            <text className="white-text-in-button">Download The App</text>
          </button>
          <img
            src={iphoneIcon}
            style={{
              marginBottom: 10,
              height: 188,
            }}
            alt="logo"
          />

          <div
            style={{ cursor: 'pointer' }}
            onClick={() => backToJobsClicked()}
          >
            <img
              src={backBlueImage}
              alt="back"
              style={{ width: 10, height: 15, marginRight: 10 }}
            />
            <text className="filter-blue-title"> Back to Jobs</text>
          </div>
        </div>
      </div>
    </Portal>
  );
};
export default SignUpErrorPopup;
