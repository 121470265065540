import { actions } from '../actions/types';

/* eslint-disable no-case-declarations */

const initialState = {
  userCoords: { lat: 0.0, lng: 0.0 },
  userInfo: {},
  address: '',
  placeId: '',
  access_token: '',
  showVerificationPopup: false,
  showSuccessPopup: false,
  zipCode: '',
  radius: '25',
};

const userReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case actions.FETCH_DATA_FROM_URL_REQUEST:
      return {
        ...state,
        zipCode: action.payload.zipCode,
        radius: action.payload.radius,
      };
    case actions.SAVE_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
      };
    case actions.SING_UP_SUCCESS:
      return {
        ...state,
        access_token: action.payload,
      };
    case actions.COMPLETE_SING_UP_SUCCESS:
      return {
        ...state,
        user: action.payload,
        showVerificationPopup: true,
      };
    case actions.HIDE_VER_POPUP:
      return {
        ...state,
        showVerificationPopup: false,
        showSuccessPopup: false,
      };
    case actions.VERIFY_PHONE_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
        showSuccessPopup: true,
        showVerificationPopup: false,
      };
    case actions.SAVE_USER_ADDRESS_SUCCESS:
    case actions.FETCH_DATA_FROM_URL_SUCCESS:
      const coords = action.payload.coords;
      const placeId = action.payload.placeId;
      const address = action.payload.address;
      const radius = action.payload.radius;

      if (!coords || !address || !placeId) {
        return state;
      }
      sessionStorage.setItem('UserCoords', JSON.stringify(coords));
      sessionStorage.setItem('UserAddress', address);
      sessionStorage.setItem('UserPlaceId', JSON.stringify(placeId));

      return {
        ...state,
        userCoords: coords,
        address,
        placeId,
        radius
      };
    default:
      return state;
  }
};
export default userReducer;
