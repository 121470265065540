import React, { useState, useEffect } from 'react';
import {
  interestedPPJob,
  clearSelectedJob,
  fetchJobById,
  hideError,
  fetchPPJobsPage,
} from '../../actions/jobs';
import { useDispatch, useSelector } from 'react-redux';
import OfferList from './OfferList';
import LearnMore from './learnMore/LearnMore';
import Filter from './Filter';
import OfferDetail from './OfferDetail';
import { RequestInfo } from '../../models/Resume';
import history from '../../history';
import SuccessLearnMore from './learnMore/SuccessLearnMore';
import { Job } from '../../models/Job';
import { createErrorObjectSelector } from '../../apis/selectors';
import { RootState } from '../../store/configureStore';
import Spinner from 'react-bootstrap/Spinner';
import CustomPortal from '../CustomPortal';
import { RouteComponentProps } from 'react-router';
import { getLocationByZipCode } from '../../actions/user';
import { useLocation } from 'react-router-dom';

const DEFAULT_RADIUS = '25';
interface MatchParams {
  zipCode: string;
  radius: string;
}
interface JobsRouteComponentProps extends RouteComponentProps<MatchParams> {
  isMobileTablet: boolean;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Jobs = ({ isMobileTablet, match }: JobsRouteComponentProps) => {
  const [showLearnMore, setShowLearnMore] = useState(false);

  const actions = ['FETCH_PP_JOBS'];
  const offers = useSelector((state: RootState) => state.ppOffers.ppOffers);

  const errorSelector = createErrorObjectSelector(actions);
  const isLoading = useSelector(
    (state: RootState) =>
      state.ppOffers.activeRequest && state.ppOffers.ppSearchParams.page === 1
  );
  let query = useQuery();

  const zipCode = query.get('zip');
  const radius = query.get('radius');

  const error = useSelector((state) => errorSelector(state));

  const showLearnMoreSuccess = useSelector(
    (state: RootState) => state.ppOffers.showLearnMoreSuccess
  );

  const selectedJob = useSelector(
    (state: RootState) => state.ppOffers.selectedJob
  );

  const reduxZipCode = useSelector((state: RootState) => state.user.zipCode);
  const dispatch = useDispatch();

  useEffect(() => {
    if (zipCode && zipCode !== reduxZipCode) {
      dispatch(
        getLocationByZipCode({
          zipCode,
          radius: radius || DEFAULT_RADIUS,
        })
      );
    } else {
      dispatch(fetchPPJobsPage());
    }
  }, []);

  const submitHasBeenClicked = (requestedInfo: RequestInfo) => {
    dispatch(interestedPPJob(requestedInfo, selectedJob.id));
  };

  const renderRightCard = () => {
    if (showLearnMoreSuccess) {
      return (
        <SuccessLearnMore
          isMobileTablet={isMobileTablet}
          backToJobsClicked={() => {
            dispatch(clearSelectedJob());
            setShowLearnMore(false);
          }}
        />
      );
    } else if (showLearnMore && !showLearnMoreSuccess) {
      return (
        <div className="col" style={{ marginBottom: 60 }}>
          <LearnMore
            isMobileTablet={isMobileTablet}
            closeLearnMore={() => setShowLearnMore(false)}
            submitHasBeenClicked={(requestedInfo: RequestInfo) =>
              submitHasBeenClicked(requestedInfo)
            }
          />
        </div>
      );
    } else {
      return (
        <div className="col" style={{ marginBottom: 60 }}>
          <OfferDetail
            isMobileTablet={isMobileTablet}
            applyPressed={(offerId: number) =>
              history.push('/resume', {
                offerId,
                office_name: selectedJob.office?.office_name || 'Office',
              })
            }
            backClicked={() => dispatch(clearSelectedJob())}
            selectedOffer={selectedJob}
            learnMordePressed={() => setShowLearnMore(true)}
          />
        </div>
      );
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Spinner
          animation="border"
          role="status"
          variant="info"
          style={{
            height: 30,
            width: 30,
            marginLeft: 20,
            marginTop: 10,
          }}
        />
      </div>
    );
  }
  return (
    <div className="container">
      {/* <div className="row"> */}
      {/* <div className="col-2">
          <Filter />
        </div> */}
      <div className="col">
        <div className="row">
          {isMobileTablet && selectedJob ? (
            renderRightCard()
          ) : (
            <div
              className="col"
              style={{
                maxHeight: window.innerHeight - 150,
                overflowY: 'scroll',
              }}
            >
              <OfferList
                clickLearnMore={() => {
                  //fetch ghost job
                  dispatch(fetchJobById(1));
                  setShowLearnMore(true);
                }}
                isMobileTablet={isMobileTablet}
                selectedOfferId={selectedJob?.id}
                showDetailsOffer={(offer: Job) => {
                  window.scrollTo(0, 0);
                  dispatch(fetchJobById(offer.id));
                  setShowLearnMore(false);
                }}
              />
            </div>
          )}
          {!isMobileTablet ? (
            <div className="col">
              <div>{renderRightCard()}</div>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
      {error ? (
        <div className="card mt-4">
          <CustomPortal
            isMobileTablet={isMobileTablet}
            title=""
            text={error?.error?.message || 'Error'}
            submitText="OK"
            submitPressed={() => dispatch(hideError(error.action))}
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};
export default Jobs;
