import { Route, Router, Switch, useLocation } from 'react-router-dom';
import ShiftsInArea from '../src/components/userInfo/ShiftsInArea';
import Jobs from '../src/components/jobs/Jobs';
import OfferDetail from '../src/components/jobs/OfferDetail';
import Resume from '../src/components/resume/Resume';
import ConfirmResume from '../src/components/resume/ConfirmResume';
import Header from '../src/components/Header';
import SuccessResume from '../src/components/resume/SuccessResume';
import './css/globalStyle.scss';
import React, { useEffect, useRef, useState } from 'react';
import history from './history';
import About from './components/About';
import { RootState } from './store/configureStore';
import { useDispatch, useSelector } from 'react-redux';
import OfferDetailedScreen from '../src/components/jobs/OfferDetailedScreen';
import LearnMoreScreen from './components/jobs/learnMore/LearnMoreScreen';
import { fetchPPJobsPage } from './actions/jobs';
import Spinner from 'react-bootstrap/Spinner';
import SignUp from './components/userInfo/SignUp';
import CustomPortal from './components/CustomPortal';
import { hideMessage } from './actions/user';

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const reduxCoords = useSelector((state: RootState) => state.user.userCoords);
  const isFinalPage = useSelector(
    (state: RootState) => state.ppOffers.isFinalPage
  );

  const message = useSelector((state: RootState) => state.alert.message);

  const { text, title, route, params } = message;
  const userCoords =
    JSON.parse(sessionStorage.getItem('UserCoords') || '{}') || reduxCoords;
  const isCoordsEnabled = userCoords.lat !== 0 && userCoords.lng !== 0;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleWindowSizeChange]);

  const isMobileTablet = width <= 768;
  const myRef: any = useRef(null);

  const isBotom = () => {
    return (
      myRef?.current?.scrollTop + window.innerHeight - 100 ===
      myRef?.current?.scrollHeight
    );
  };
  const dispatch = useDispatch();
  const handleScroll = () => {
    if (isCoordsEnabled && isBotom()) {
      dispatch(fetchPPJobsPage());
    }
  };

  return (
    <Router history={history}>
      <Header isMobileTablet={isMobileTablet} />
      <Switch>
        <Route
          path="/"
          exact
          component={(props: any) => (
            <ShiftsInArea isMobileTablet={isMobileTablet} {...props} />
          )}
        />
        <Route
          path="/jobs"
          exact
          component={(props: any) => (
            <div>
              {isCoordsEnabled ? (
                <div
                  ref={myRef}
                  onScroll={handleScroll}
                  style={{
                    height: window.innerHeight - 150,
                  }}
                >
                  <Jobs isMobileTablet={isMobileTablet} {...props} />
                  {!isFinalPage && isBotom() ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: 'center',
                      }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        variant="info"
                        style={{
                          height: 30,
                          width: 30,
                          marginLeft: 20,
                          marginTop: 10,
                        }}
                      />
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              ) : (
                <ShiftsInArea isMobileTablet={isMobileTablet} {...props} />
              )}
            </div>
          )}
        />
        <Route
          path="/jobs/:id/interested"
          component={(props: any) => (
            <LearnMoreScreen isMobileTablet={isMobileTablet} {...props} />
          )}
        />
        <Route
          path="/jobs/:id"
          exact
          component={(props: any) => (
            <OfferDetailedScreen isMobileTablet={isMobileTablet} {...props} />
          )}
        />

        <Route
          path="/resume"
          component={(props: any) => (
            <Resume isMobileTablet={isMobileTablet} {...props} />
          )}
        />
        <Route path="/about" component={About} />
        <Route path="/signup" component={SignUp} />

        <Route
          path="/confirm"
          component={(props: any) => (
            <ConfirmResume isMobileTablet={isMobileTablet} {...props} />
          )}
        />
        <Route
          path="/success-resume"
          component={(props: any) => (
            <SuccessResume isMobileTablet={isMobileTablet} {...props} />
          )}
        />
      </Switch>
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 15,
          overflowX: 'hidden',
        }}
      >
        <div
          className="main-blue-button mt-0 mb-0 mr-3 btn"
          style={{ height: 36, maxWidth: 150 }}
          onClick={() => window.open('https://tempmee.com/app', '_blank')}
        >
          <text className="white-text-in-button" style={{ fontSize: 13 }}>
            Download the App
          </text>
        </div>
        <text
          className="filter-blue-title"
          style={{ fontSize: 13, textAlign: 'center' }}
        >
          See All Temp & Permanent Jobs
        </text>
      </div>

      {text ? (
        <CustomPortal
          isMobileTablet={isMobileTablet}
          title={title}
          text={text}
          submitPressed={() => {
            if (route === 'back') {
              history.goBack();
            } else {
              history.push(route, params);
            }
            dispatch(hideMessage());
          }}
          submitText="OK"
        />
      ) : null}
    </Router>
  );
}

export default App;
