export const actions = {
  FETCH_PP_JOBS_REQUEST: 'FETCH_PP_JOBS_REQUEST',
  FETCH_PP_JOBS_SUCCESS: 'FETCH_PP_JOBS_SUCCESS',
  FETCH_PP_JOBS_FAILURE: 'FETCH_PP_JOBS_FAILURE',

  FETCH_JOB_BY_ID_REQUEST: 'FETCH_JOB_BY_ID_REQUEST',
  FETCH_JOB_BY_ID_SUCCESS: 'FETCH_JOB_BY_ID_SUCCESS',
  FETCH_JOB_BY_ID_FAILURE: 'FETCH_JOB_BY_ID_FAILURE',

  CLEAR_SELECTED_JOB_REQUEST: 'CLEAR_SELECTED_JOB_REQUEST',
  CLEAR_SELECTED_JOB_SUCCESS: 'CLEAR_SELECTED_JOB_SUCCESS',
  CLEAR_SELECTED_JOB_FAILURE: 'CLEAR_SELECTED_JOB_FAILURE',

  APPLY_PP_JOB_REQUEST: 'APPLY_PP_JOB_REQUEST',
  APPLY_PP_JOB_SUCCESS: 'APPLY_PP_JOB_SUCCESS',
  APPLY_PP_JOB_FAILURE: 'APPLY_PP_JOB_FAILURE',

  LEARN_MORE_PP_JOB_REQUEST: 'LEARN_MORE_PP_JOB_REQUEST',
  LEARN_MORE_PP_JOB_SUCCESS: 'LEARN_MORE_PP_JOB_SUCCESS',
  LEARN_MORE_PP_JOB_FAILURE: 'LEARN_MORE_PP_JOB_FAILURE',

  SAVE_USER_ADDRESS_REQUEST: 'SAVE_USER_ADDRESS_REQUEST',
  SAVE_USER_ADDRESS_SUCCESS: 'SAVE_USER_ADDRESS_SUCCESS',
  SAVE_USER_ADDRESS_FAILURE: 'SAVE_USER_ADDRESS_FAILURE',

  SAVE_USER_INFO_REQUEST: 'SAVE_USER_INFO_REQUEST',
  SAVE_USER_INFO_SUCCESS: 'SAVE_USER_INFO_SUCCESS',
  SAVE_USER_INFO_FAILURE: 'SAVE_USER_INFO_FAILURE',

  SING_UP_REQUEST: 'SING_UP_REQUEST',
  SING_UP_SUCCESS: 'SING_UP_SUCCESS',
  SING_UP_FAILURE: 'SING_UP_FAILURE',

  COMPLETE_SING_UP_REQUEST: 'COMPLETE_SING_UP_REQUEST',
  COMPLETE_SING_UP_SUCCESS: 'COMPLETE_SING_UP_SUCCESS',
  COMPLETE_SING_UP_FAILURE: 'COMPLETE_SING_UP_FAILURE',

  FETCH_DATA_FROM_URL_REQUEST: 'FETCH_DATA_FROM_URL_REQUEST',
  FETCH_DATA_FROM_URL_SUCCESS: 'FETCH_DATA_FROM_URL_SUCCESS',
  FETCH_DATA_FROM_URL_FAILURE: 'FETCH_DATA_FROM_URL_FAILURE',

  VERIFY_PHONE_REQUEST: 'VERIFY_PHONE_REQUEST',
  VERIFY_PHONE_SUCCESS: 'VERIFY_PHONE_SUCCESS',
  VERIFY_PHONE_FAILURE: 'VERIFY_PHONE_FAILURE',

  DELETE_USER_ADDRESS: 'DELETE_USER_ADDRESS',
  SHOW_LEARN_MORE_SUCCESS: 'SHOW_LEARN_MORE_SUCCESS',
  SHOW_APPLY_SUCCESS: 'SHOW_APPLY_SUCCESS',
  HIDE_VER_POPUP: 'HIDE_VER_POPUP',

  SHOW_MESSAGE: 'SHOW_MESSAGE',
  HIDE_MESSAGE: 'HIDE_MESSAGE',

  HIDE_ERROR: 'HIDE_ERROR',
};
