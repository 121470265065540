import React, { useState, useEffect, useRef } from 'react';
import Portal from '../Portal';

const VerificationPopup = ({
  isMobileTablet,
  submitPressed,
  cancelPressed,
}: {
  isMobileTablet: boolean;
  submitPressed: (code: string) => void;
  cancelPressed: () => void;
}) => {
  const inputRef: React.RefObject<HTMLInputElement> = useRef(null);
  const [code, setCode] = useState('');

  useEffect(() => {
    inputRef?.current?.focus();
  });
  const inputRender = () => {
    return (
      <input
        ref={inputRef}
        className="input-blue-border form-control mb-2"
        value={code}
        onChange={(value) => setCode(value.currentTarget.value)}
      />
    );
  };
  return (
    <Portal>
      <div
        className="modal"
        style={{ minWidth: isMobileTablet ? '100%' : 1140 }}
      >
        <div
          className="modal_content"
          style={{ width: isMobileTablet ? '90%' : '50%', maxWidth: 650 }}
        >
          <h1
            className="filter-blue-title"
            style={{ fontSize: 24, marginBottom: 10 }}
          >
            Phone verification
          </h1>
          <p
            className="common-text-regular"
            style={{ margin: '10px 10px', textAlign: 'center' }}
          >
            We have sent you and SMS with a code to the number:
          </p>
          {inputRender()}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              alignSelf: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              className="secondary-blue-button btn"
              onClick={() => {
                cancelPressed();
              }}
              style={{
                width: '45%',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                marginTop: 10,
                backgroundColor: '#97A5AC',
              }}
            >
              <text className="white-text-in-button">Cancel</text>
            </div>
            <div
              className="secondary-blue-button btn"
              onClick={() => {
                submitPressed(code);
              }}
              style={{
                width: '45%',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                marginTop: 10,
              }}
            >
              <text className="white-text-in-button">Submit</text>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};
export default VerificationPopup;
