import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import history from '../../history';
import SignUpForm from './SignUpForm';
import PhoneVerification from './PhoneVerification';

import {
  createErrorObjectSelector,
  createLoadingSelector,
} from '../../apis/selectors';
import { RootState } from '../../store/configureStore';
import Spinner from 'react-bootstrap/Spinner';
import CustomPortal from '../CustomPortal';
import { useDispatch, useSelector } from 'react-redux';
import { hideError } from '../../actions/jobs';
import { hideVerificationPopup, verifyPhone } from '../../actions/user';
import SuccessSignUp from './SignUpSuccess';
import SignUpErrorPopup from './SignUpErrorPopup';

const SignUp = ({
  showDetailsOffer,
  isMobileTablet,
}: {
  showDetailsOffer: () => void;
  isMobileTablet: boolean;
}) => {
  const location = useLocation<{ offerId: number; office_name: string }>();
  const offerInfo = location.state;
  const actions = ['SING_UP', 'VERIFY_PHONE'];
  const customErrorDownloadApp = ['COMPLETE_SING_UP'];

  const errorSelector = createErrorObjectSelector(actions);
  const customErrorSelector = createErrorObjectSelector(customErrorDownloadApp);

  const loadingSelector = createLoadingSelector(actions);

  const error = useSelector((state) => errorSelector(state));
  const customError = useSelector((state) => customErrorSelector(state));

  const isLoading = useSelector((state) => loadingSelector(state));

  const showVerificationPopup = useSelector(
    (state: RootState) => state.user.showVerificationPopup
  );
  const showSuccessPopup = useSelector(
    (state: RootState) => state.user.showSuccessPopup
  );

  const dispatch = useDispatch();

  return (
    <div
      className="container"
      style={{
        maxWidth: isMobileTablet ? '100%' : 550,
        marginTop: isMobileTablet ? 0 : 30,
        marginBottom: 50,
      }}
    >
      <SignUpForm isMobileTablet={isMobileTablet} isLoading={isLoading} />
      {error ? (
        <div className="card mt-4">
          <CustomPortal
            isMobileTablet={isMobileTablet}
            title=""
            text={error?.error?.message || 'Error'}
            submitText="OK"
            submitPressed={() => dispatch(hideError(error.action))}
          />
        </div>
      ) : (
        <div />
      )}
      {customError ? (
        <div className="card mt-4">
          <SignUpErrorPopup
            isMobileTablet={isMobileTablet}
            backToJobsClicked={() => {
              dispatch(hideError(customError.action));
              history.push('/jobs');
            }}
          />
        </div>
      ) : (
        <div />
      )}

      {showVerificationPopup ? (
        <div className="card mt-4">
          <PhoneVerification
            isMobileTablet={isMobileTablet}
            submitPressed={(code: string) => dispatch(verifyPhone(code))}
            cancelPressed={() => dispatch(hideVerificationPopup())}
          />
        </div>
      ) : (
        <div />
      )}

      {showSuccessPopup ? (
        <div className="card mt-4">
          <SuccessSignUp
            isMobileTablet={isMobileTablet}
            backToJobsClicked={() => {
              dispatch(hideVerificationPopup());
              history.push('/jobs');
            }}
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default SignUp;
