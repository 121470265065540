import { Job } from '../../models/Job';

const OfferPreviewCard = ({
  showDetailsOffer,
  offer,
  isSelectedOffer,
}: {
  showDetailsOffer: (offer: Job) => void;
  offer: Job;
  isSelectedOffer: boolean;
}) => {
  const getProfessionalName = () => {
    if (offer.profession === 'RDH') {
      return 'Registered Dental Hygienist';
    }
    if (offer.profession === 'DA') {
      return 'Registered Dental Assistant';
    }

    return 'Registered Dental Hygienist';
  };

  return (
    <div
      key={offer.id}
      onClick={() => showDetailsOffer(offer)}
      className="offer-card card mb-3"
      style={{
        borderColor: isSelectedOffer ? '#3eb0ca' : 'transparent',
        cursor: 'pointer',
      }}
    >
      <div className="border-bottom">
        <div
          className="col"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <text className="filter-blue-title ">
            {offer.office?.office_name || ''}
          </text>

          <text className="common-text-bold ">
            {offer.office?.address || ''}
          </text>
        </div>
      </div>
      <div>
        <div className="col mt-2">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <text className="filter-blue-title">Position</text>

            <text className="common-text-bold">{getProfessionalName()}</text>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            className="col"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <text className="filter-blue-title">Pay Range</text>

            <text className="common-text-bold">{offer.rate}</text>
          </div>
          <div
            className="col"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <text className="filter-blue-title">Schedule Type</text>

            <text className="common-text-bold">{offer.position}</text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferPreviewCard;
