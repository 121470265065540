import React from 'react';
import Portal from './Portal';

const CustomPortal = ({
  isMobileTablet,
  title,
  text,
  submitPressed,
  cancelPressed,
  cancelText,
  submitText,
}: {
  isMobileTablet: boolean;
  title: string;
  text: string;
  submitText: string;
  cancelText?: string;
  cancelPressed?: () => void;
  submitPressed: () => void;
}) => (
  <Portal>
    <div
      className="modal"
      style={{
        minWidth: isMobileTablet ? '100%' : 1140,
      }}
    >
      <div
        className="modal_content"
        style={{
          width: isMobileTablet ? '90%' : '50%',
          maxWidth: 650,
          borderRadius: 10,
          padding: 30,
          boxShadow: '0px 2px 4px rgba(0,0,0,0.5)',
        }}
      >
        <h1
          className="filter-blue-title"
          style={{ fontSize: 24, marginBottom: 10, textAlign: 'center' }}
        >
          {title || ''}
        </h1>
        <p
          className="common-text-regular"
          style={{ margin: '10px 10px', textAlign: 'center' }}
        >
          {text || ''}
        </p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '50%',
            alignSelf: 'center',
          }}
        >
          <div
            className="secondary-blue-button btn"
            onClick={() => {
              submitPressed();
            }}
            style={{
              width: '100%',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              marginTop: 10,
            }}
          >
            <text className="white-text-in-button">{submitText}</text>
          </div>
        </div>
      </div>
    </div>
  </Portal>
);
export default CustomPortal;
