import React, { useState } from 'react';
import history from '../../history';
import { useLocation } from 'react-router-dom';
import backBlueImage from '../../images/back_blue.svg';
import { ResumeInfoInterface } from '../../models/Resume';
import { useDispatch, useSelector } from 'react-redux';
import { applyPPJob, hideError } from '../../actions/jobs';
import CustomPortal from '../CustomPortal';
import {
  createErrorObjectSelector,
  createLoadingSelector,
} from '../../apis/selectors';
import Spinner from 'react-bootstrap/Spinner';

const ConfirmResume = ({ isMobileTablet }: { isMobileTablet: boolean }) => {
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [showCheckboxAlert, setShowCheckboxAlert] = useState(false);

  const actions = ['APPLY_PP_JOB'];

  const loadingSelector = createLoadingSelector(actions);
  const errorSelector = createErrorObjectSelector(actions);
  const isLoading = useSelector((state) => loadingSelector(state));
  const error = useSelector((state) => errorSelector(state));

  const location = useLocation<ResumeInfoInterface>();
  const resumeInfo = location.state;
  const dispatch = useDispatch();

  const renderLine = (title: string, value: string) => (
    <div style={{ marginBottom: 15, width: '100%' }}>
      <text className="common-text-bold"> {title}</text>
      <text className="common-text-regular"> {value}</text>
    </div>
  );
  const applyHasBeenClicked = () => {
    if (!checkboxValue) {
      setShowCheckboxAlert(true);
    } else {
      dispatch(applyPPJob(resumeInfo));
    }
  };
  return (
    <div
      className={
        isMobileTablet ? 'mobile-card' : 'container card mt-4 content-card mb-3'
      }
    >
      <div
        style={{ width: '100%', cursor: 'pointer' }}
        onClick={() => history.goBack()}
      >
        <img src={backBlueImage} alt="back" className="back-blue-icon" />
        <text
          className="filter-blue-title"
          style={{
            textAlign: 'center',
            width: '100%',
          }}
        >
          Your Resume
        </text>
      </div>
      <text
        className="common-text-bold mt-3 mb-2"
        style={{ fontSize: 24, textAlign: 'left', width: '100%' }}
      >
        {resumeInfo.first_name} {resumeInfo.last_name}
      </text>

      <div className="row mb-2 mt-2 w-100">
        <div className="col">
          <text className="common-text-bold row">
            Hygienist License Number:
          </text>
          <text className="common-text-regular row">{resumeInfo.licId}</text>
        </div>
        <div className="col">
          <text className="common-text-bold row">State:</text>
          <text className="common-text-regular row">{resumeInfo?.state}</text>
        </div>
      </div>
      <div
        className="row border-bottom w-100"
        style={{ paddingBottom: 20, marginBottom: 20 }}
      >
        <div className="col">
          <text className="common-text-bold row">Year Issued:</text>
          <text className="common-text-regular row">
            {resumeInfo.issuedYear}
          </text>
        </div>
        <div className="col">
          <text className="common-text-bold row"> School Attended: </text>
          <text className="common-text-regular row">
            {resumeInfo.schoolName}
          </text>
        </div>
      </div>

      {renderLine('Available:', resumeInfo.scheduleType)}
      {renderLine('Years of Experience:', resumeInfo.yearsOfExperience)}
      {renderLine('List your last 3 employers:', resumeInfo.officesWorkedIn)}
      {renderLine(
        'How did you find out about this job?',
        resumeInfo.howFindOutAboutJob
      )}
      {renderLine('Available to Interview:', resumeInfo.interviewAvailability)}
      <div
        className="border-top mb-4"
        style={{
          paddingTop: 20,
          marginBottom: 10,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <text className="filter-blue-title"> Description</text>
        <text className="common-text-regular"> {resumeInfo.about}</text>
      </div>

      <div className="col">
        <div className="input-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="customCheck1"
              checked={checkboxValue}
              onClick={(val) => setCheckboxValue(!checkboxValue)}
              required
            />
            <label
              className="custom-control-label common-text-regular"
              htmlFor="customCheck1"
            >
              I certify the above information is true and correct to the best of
              my knowledge. I understand that false information may disqualify
              me from this position and the TempMee platform.
            </label>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Spinner
            animation="border"
            role="status"
            variant="info"
            style={{
              height: 30,
              width: 30,
              marginTop: 45,
            }}
          />
        </div>
      ) : (
        <div
          className="main-blue-button btn"
          onClick={() => applyHasBeenClicked()}
          style={{
            width: '70%',
          }}
        >
          <p
            className="white-text-in-button"
            style={{
              justifySelf: 'center',
              margin: 0,
            }}
          >
            Apply
          </p>
        </div>
      )}
      {showCheckboxAlert ? (
        <div className="card mt-4">
          <CustomPortal
            isMobileTablet={isMobileTablet}
            title=""
            text="Please `Certify` your information is true and correct."
            submitText="OK"
            submitPressed={() => setShowCheckboxAlert(false)}
          />
        </div>
      ) : (
        <div />
      )}

      {error ? (
        <div className="card mt-4">
          <CustomPortal
            isMobileTablet={isMobileTablet}
            title=""
            text={error?.error?.message || 'Error'}
            submitText="OK"
            submitPressed={() => dispatch(hideError(error.action))}
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};
export default ConfirmResume;
