import { actions } from '../actions/types';

const errorReducer = (state = {}, action: { type: string; payload?: any }) => {
  if (action.type === actions.HIDE_ERROR) {
    return {
      ...state,
      [action.payload]: '',
    };
  }

  const { type, payload } = action;
  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);
  if (!matches) return state;
  const [, requestName, requestState] = matches;
  const responseMessage =
    payload?.response?.data?.message || payload?.message || '';
  const message =
    requestState === 'FAILURE' ? responseMessage || payload.message : '';
  return {
    ...state,
    [requestName]: {
      type: 'DEFAULT',
      message,
    },
  };
};

export default errorReducer;
