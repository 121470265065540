import React from 'react';
import tmLogo from '../images/logoIcon.svg';
import history from '../history';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/configureStore';
import { deleteUserAddress } from '../actions/jobs';

const Header = ({ isMobileTablet }: { isMobileTablet: boolean }) => {
  const reduxAddress = useSelector((state: RootState) => state.user.address);

  const reduxCoords = useSelector((state: RootState) => state.user.userCoords);
  const radius = useSelector((state: RootState) => state.user.radius);

  const userCoords =
    JSON.parse(sessionStorage.getItem('UserCoords') || '{}') || reduxCoords;

  const isCoordsEnabled = userCoords.lat !== 0 && userCoords.lng !== 0;
  const address = sessionStorage.getItem('UserAddress') || reduxAddress;

  const dispatch = useDispatch();
  return (
    <nav
      style={{
        backgroundColor: '#307F92',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10,
      }}
    >
      <img
        src={tmLogo}
        alt="tempmee_logo"
        style={{
          cursor: 'pointer',
          marginLeft: isMobileTablet ? 20 : 100,
          marginRight: 20,
          height: 80,
          minWidth: 90,
          width: 'auto',
          objectFit: 'contain',
          alignSelf: 'flex-start',
          justifySelf: 'flex-start',
        }}
        onClick={() => dispatch(deleteUserAddress())}
      />
      {isCoordsEnabled && address && !isMobileTablet ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <text style={{ fontSize: 14, fontFamily: 'Nunito', color: 'white' }}>
            All jobs in {radius} mile radius
          </text>
          <text style={{ fontSize: 14, fontFamily: 'Nunito', color: 'white' }}>
            {address}
          </text>
          <text
            style={{
              fontSize: 14,
              fontFamily: 'Nunito',
              color: 'white',
              fontWeight: 'bold',
              cursor: 'pointer',
              textDecorationLine: 'underline',
            }}
            onClick={() => dispatch(deleteUserAddress())}
          >
            Search In Different Location
          </text>
        </div>
      ) : (
        <div />
      )}
      <div
        className="row"
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          marginRight: isMobileTablet ? 10 : 50,
        }}
      >
        <text
          className="col white-header-text"
          onClick={() => window.open('https://tempmee.com/', '_blank')}
        >
          Home
        </text>
        <a
          className="col white-header-text"
          href={`tel:+17864602170`}
          style={{ width: isMobileTablet ? undefined : 350 }}
        >
          786-460-2170
        </a>
      </div>
    </nav>
  );
};
export default Header;
