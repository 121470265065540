import defaultProfile from '../../images/defaultProfile.svg';
import logoIcon from '../../images/logo_small.svg';
import { Job } from '../../models/Job';
import backBlueImage from '../../images/back_blue.svg';

const OfferDetail = ({
  selectedOffer,
  learnMordePressed,
  applyPressed,
  isMobileTablet,
  backClicked,
}: {
  selectedOffer: Job;
  learnMordePressed: () => void;
  applyPressed: (offerId: number) => void;
  backClicked: () => void;
  isMobileTablet: boolean;
}) => {
  const getProfessionalName = (): string => {
    const professionNames: { RDH: string; DA: string; DN: string; FD: string } =
      {
        RDH: 'Dental Hygienist',
        DA: 'Dental Assistant',
        DN: 'Associate Dentist',
        FD: 'Front Desk',
      };

    return (
      (professionNames as any)[selectedOffer?.profession] || 'Dental Hygienist'
    );
  };

  const renderLine = (title: string, value: string) => {
    return (
      <div
        className="border-bottom w-100"
        style={{ paddingBottom: 13, paddingTop: 13 }}
      >
        <text className="input-title"> {title}</text>
        <text className="common-text-regular"> {value}</text>
      </div>
    );
  };

  if (!selectedOffer) {
    return (
      <div
        className="container card mt-4 "
        style={{
          padding: 70,
          borderRadius: 10,
          boxShadow: '0px 3px 10px -3px #738791',
          height: 650,
        }}
      >
        <div
          className="card-body"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logoIcon} style={{ width: 50 }} alt="profile" />
          <text className="filter-blue-title mt-3">Welcome!</text>
          <text className="input-title mt-2">
            Select a job to see details here!
          </text>
        </div>
      </div>
    );
  }
  return (
    <div
      className="container card mt-4 mb-2"
      style={{
        padding: isMobileTablet ? 30 : '45px 70px 50px 70px',
        maxWidth: isMobileTablet ? '100%' : 550,
        display: 'flex',
        borderRadius: 10,
        alignSelf: 'center',
        boxShadow: '0px 3px 10px -3px #738791',
        overflowY: 'auto',
      }}
    >
      {isMobileTablet ? (
        <div
          style={{ cursor: 'pointer', marginBottom: 20 }}
          onClick={() => backClicked()}
        >
          <img
            src={backBlueImage}
            alt="back"
            style={{ width: 10, height: 15, marginRight: 10 }}
          />
          <text className="filter-blue-title"> Back to Jobs</text>
        </div>
      ) : (
        <div />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          src={defaultProfile}
          style={{ width: 87, height: 87 }}
          alt="logo"
        />
        <text className=" input-title mt-3">
          {selectedOffer.office?.office_name || ''}
        </text>
        <text
          className="common-text-regular mb-4"
          style={{ textAlign: 'center', width: '60%' }}
        >
          {selectedOffer.office?.address || ''}
        </text>

        {renderLine('Profession:', getProfessionalName())}
        {renderLine('Start Date:', selectedOffer.start_date)}
        {renderLine('Schedule Type:', selectedOffer.position)}
        {renderLine('Pay Range:', selectedOffer.rate)}
        {renderLine('Benefits:', selectedOffer.benefits)}
        <text
          className="filter-blue-title mt-2 w-100"
          style={{ textAlign: 'left' }}
        >
          Description
        </text>
        <text
          className="common-text-regular mb-4"
          style={{ alignSelf: 'flex-start' }}
        >
          {selectedOffer.content}
        </text>
        <div
          className="row mt-2"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            className="col round-button"
            onClick={() => learnMordePressed()}
            style={{
              backgroundColor: '#4FC0CF',
            }}
          >
            <text
              className="white-text-in-button"
              style={{ fontSize: 14, textAlign: 'center' }}
            >
              Learn More
            </text>
          </div>
          <div
            onClick={() => applyPressed(selectedOffer.id)}
            className="col round-button"
            style={{ backgroundColor: '#307F92' }}
          >
            <text className="white-text-in-button" style={{ fontSize: 14 }}>
              Apply
            </text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferDetail;
