import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { logEvent } from 'firebase/analytics';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY_ANALYTICS,
  authDomain: 'job-board-eb9a6.firebaseapp.com',
  projectId: 'job-board-eb9a6',
  storageBucket: 'job-board-eb9a6.appspot.com',
  messagingSenderId: '118737439578',
  appId: '1:118737439578:web:83b25ee294ff834710c163',
  measurementId: 'G-LKPP86KGZN',
};

// Initialize Firebase
let analytics;
if (process.env.REACT_APP_TEMP_MEE_ENV === 'production') {
  const app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);
}

export const logCustomEvent = async (eventName, eventParams) => {
  if (process.env.REACT_APP_TEMP_MEE_ENV === 'production') {
    await logEvent(analytics, eventName, eventParams);
  }
};
