import { actions } from '../actions/types';

const initialState = {
  message: {
    text: null,
    title: null,
    route: null,
    params: null,
  },
};
export default (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case actions.SHOW_MESSAGE:
      const { payload } = action;

      const { message, title, route, params } = payload;
      return {
        ...state,
        message: {
          text: message,
          title,
          route,
          params,
        },
      };
    case actions.HIDE_MESSAGE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
